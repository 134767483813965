<template>
    <div>
        <v-card class="primary darken-2" style="" >
            <v-card-text style="font-size: 12px; ">
                <p class="white--text subheading pa-0 ma-0 text-xs-center"><b> Bravo</b> your njangi application is completed</p>
            </v-card-text>
        </v-card>

        <v-card color="white pa-3 " style="background-color: rgb(255, 255, 255)!important" >
            <b><span class="success--text">Step 3/3:</span> Application Review</b>
            <v-layout wrap row class="pb-0 mb-1" >
                <v-flex xs12 class="pb-0 mb-1" >
                    <p>Your application is being reviewed and you will be contacted to show proof of this information</p>
                </v-flex>
            </v-layout>
            <div class="text-xs-center">
                <v-btn class="white--text elevation-6" :to="'/njangi/groups/types/Applied'" color="teal" >
                    <i class="fas fa-share mr-1"> </i>
                    Return Home
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {

            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Njangi Review"
        },

        methods:{

        },
    }
</script>
